import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { isEmpty } from '@/utilities';
const modelUrl = 'chats';

const state = {
    unreadChats: [],
    chatOrders: [],
}

const getters = {
    chatOrders: state => state.chatOrders,
    unreadChats: state => state.unreadChats,
    unreadChatsByOrder: state => (admin_service, orderId, fromUser) => {
        if(!fromUser){
            return state.unreadChats.filter(i => i.admin_service == admin_service && i.orderable_id == orderId && isEmpty(i.read_at))
        }
        return state.unreadChats.filter(i => i.admin_service == admin_service && i.orderable_id == orderId && isEmpty(i.read_at) && i.from_userable_type == fromUser.to_userable_type && i.from_userable_id == fromUser.to_userable_id)
    },
}

const actions = {
    getChats(ctx, payload){
        const form  = new FormData();
        Object.keys(payload).forEach(k => {
            form.append(k, payload[k]);
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/user-chats`, form)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    getOrderChats(ctx, payload){
        const form  = new FormData();
        Object.keys(payload).forEach(k => {
            form.append(k, payload[k]);
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/order-chats`, form)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    getOrders(ctx){
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/orders`)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit("addUnreadChats", data.responseData.unread_chats)
                        ctx.commit("addChatOrder", data.responseData.orders);
                        return resolve(data.responseData.orders)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    chatStore(ctx, payload){
        const form  = new FormData();
        Object.keys(payload).forEach(k => {
            form.append(k, payload[k]);
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/chat-store`, form)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    markReadChat(ctx, payload){
        const form  = new FormData();
        Object.keys(payload).forEach(k => {
            form.append(k, payload[k]);
        })
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/${modelUrl}/chat-mark-read`, form)
                .then(response => {
                    const { data } = response
                    if (data._statusCode === 200) {
                        ctx.commit("updateUnreadChats", payload)
                        return resolve(data.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    addToUnread(ctx, chat){
        ctx.commit("addToUnread", chat)
    }
}

const mutations = {
    addUnreadChats: (state, chats) => {
        state.unreadChats = !isEmpty(chats) ? chats : []
    },
    updateUnreadChats: (state, {admin_service, orderable_id, from_userable_type, from_userable_id, to_userable_type, to_userable_id}) => {
        state.unreadChats = state.unreadChats.filter(i => !(
            i.admin_service == admin_service 
            && i.orderable_id == orderable_id 
            && i.to_userable_type == from_userable_type 
            && i.to_userable_id == from_userable_id
            && i.from_userable_type == to_userable_type 
            && i.from_userable_id == to_userable_id
        ))
    },
    addToUnread: (state, chat) => {
        state.unreadChats.push(chat)
    },
    addChatOrder: (state, orders) => {
        console.log(orders, "orders");
        state.chatOrders = orders
    },
}

export const chat = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
